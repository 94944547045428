import { isString } from "util/type-guard";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { MaterialReactTable } from "material-react-table";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useSendBackJobDialog } from "render-hooks/useSendBackDialog";
import { isTimeoutError } from "services/common/type-guard";
import { useGetProcessResultQuery } from "services/data";
import { usePrepareAndDownloadFileMutation } from "services/file";
import { useApproveJobMutation, useGetJobQuery } from "services/job";

import { kOpts_CheckResult, kOpts_FileType, kOpts_CheckInfo } from "../../../common/const_defs";
import { CommonSelect } from "../../../components/molecular/CommonSelect";
import { LabelInput } from "../../../components/molecular/LabelInput";

import type {
  CheckResultDetailNode,
  CheckResultNode,
  CompanyData,
} from "../../../common/data_defs";
import type { SelectNode } from "../../../common/type_defs";
import type { RowSelectionState, Updater } from "@tanstack/react-table";
import type { MRT_ColumnDef } from "material-react-table";
import type { ChangeEvent, FC } from "react";

type FileCheckFilterProps = {
  filters: Record<string, unknown>;
  onChanged: (v: Record<string, unknown>) => void;
};

const FileCheckFilterPanel: FC<FileCheckFilterProps> = ({ filters, onChanged }) => {
  const [groupCode, setGroupCode] = useState<string>("");
  const [companyCode, setCompanyCode] = useState<string>("");
  const [branchCode, setBranchCode] = useState<string>("");
  const [chkRstType, setChkRstType] = useState<string>("");
  const [chkRstRequired, setChkRstRequired] = useState<string>("");
  const [chkRstRewrite, setChkRstRewrite] = useState<string>("");
  const [chkRstTango, setChkRstTango] = useState<string>("");
  const [chkRstInfo, setChkRstInfo] = useState<string>("");

  // useEffect(() => {
  //   setGroupCode(filters.groupCode as string);
  //   setCompanyCode(filters.companyCode as string);
  //   setBranchCode(filters.branchCode as string);
  //   setChkRstType(findSelNode(filters.typeChkRst + '', kOpts_CheckResult).value);
  //   setChkRstRequired(findSelNode(filters.requiredChkRst + '', kOpts_CheckResult).value);
  //   setChkRstRewrite(findSelNode(filters.rewriteChkRst + '', kOpts_CheckResult).value);
  //   setChkRstTango(findSelNode(filters.tangoChkRst + '', kOpts_CheckResult).value);
  // }, [filters, setGroupCode, setCompanyCode, setBranchCode]);

  const handleGroupCodeChanged = (txt: string) => {
    setGroupCode(txt);
  };
  const handleBranchCodeChanged = (txt: string) => {
    setBranchCode(txt);
  };
  const handleCompanyCodeChanged = (txt: string) => {
    setCompanyCode(txt);
  };
  const handleTypeChkRstChanged = (nv: SelectNode) => {
    setChkRstType(nv.value);
  };
  const handleRequiredChkRstChanged = (nv: SelectNode) => {
    setChkRstRequired(nv.value);
  };
  const handleRewriteChkRstChanged = (nv: SelectNode) => {
    setChkRstRewrite(nv.value);
  };
  const handleTangoChkRstChanged = (nv: SelectNode) => {
    setChkRstTango(nv.value);
  };
  const handleInfoChkRstChanged = (nv: SelectNode) => {
    setChkRstInfo(nv.value);
  };

  const collectFilters = (): Record<string, unknown> => ({
    groupCode,
    branchCode,
    companyCode,
    typeChkRst: chkRstType,
    requiredChkRst: chkRstRequired,
    rewriteChkRst: chkRstRewrite,
    tangoChkRst: chkRstTango,
    infoChkRst: chkRstInfo,
  });
  const handleReset = () => {
    setGroupCode("");
    setBranchCode("");
    setCompanyCode("");
    setChkRstType("");
    setChkRstRequired("");
    setChkRstRewrite("");
    setChkRstTango("");
    setChkRstInfo("");
  };
  const handleApply = () => {
    // onSearch(collectFilters());
    onChanged(collectFilters());
  };

  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", gap: 1, justifyContent: "flex-start" }}>
        <Typography sx={{ width: "50px", mt: "25px" }}>CE</Typography>
        <LabelInput
          key="groupCode"
          name="groupCode"
          label="Group Code"
          value={groupCode}
          onChanged={handleGroupCodeChanged}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="companyCode"
          name="companyCode"
          label="Company Code"
          value={companyCode}
          onChanged={handleCompanyCodeChanged}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
        <LabelInput
          key="branchCode"
          name="branchCode"
          label="Branch Code"
          value={branchCode}
          onChanged={handleBranchCodeChanged}
          sx={{ minWidth: "120px" }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          size="small"
        />
      </Stack>
      <Stack direction="row" sx={{ width: "100%", gap: 1, justifyContent: "flex-start" }}>
        <Typography sx={{ width: "50px", mt: "25px" }}>Status</Typography>
        <Stack direction="column">
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Type
          </Typography>
          <CommonSelect
            value={chkRstType}
            options={kOpts_CheckResult}
            onChanged={handleTypeChkRstChanged}
            sxx={{ minWidth: 150 }}
          />
        </Stack>
        <Stack direction="column">
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Mandatory
          </Typography>
          <CommonSelect
            value={chkRstRequired}
            options={kOpts_CheckResult}
            onChanged={handleRequiredChkRstChanged}
            sxx={{ minWidth: 150 }}
          />
        </Stack>
        <Stack direction="column">
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Immutable
          </Typography>
          <CommonSelect
            value={chkRstRewrite}
            options={kOpts_CheckResult}
            onChanged={handleRewriteChkRstChanged}
            sxx={{ minWidth: 150 }}
          />
        </Stack>
        <Stack direction="column">
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Reconciliation
          </Typography>
          <CommonSelect
            value={chkRstTango}
            options={kOpts_CheckResult}
            onChanged={handleTangoChkRstChanged}
            sxx={{ minWidth: 150 }}
          />
        </Stack>
        <Stack direction="column">
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Info
          </Typography>
          <CommonSelect
            value={chkRstInfo}
            options={kOpts_CheckInfo}
            onChanged={handleInfoChkRstChanged}
            sxx={{ minWidth: 150 }}
          />
        </Stack>
        <Box sx={{ flex: 1 }} />
        <Button
          variant="contained"
          onClick={handleApply}
          sx={{ maxHeight: 36, alignSelf: "flex-end" }}
        >
          Apply
        </Button>
        <Button
          variant="outlined"
          onClick={handleReset}
          sx={{ maxHeight: 36, alignSelf: "flex-end" }}
        >
          Reset
        </Button>
      </Stack>
    </Box>
  );
};

const CheckResultStatusCell: FC<{ value: string }> = ({ value }) => {
  switch (value) {
    case "APPROVED":
      return <Chip label="Approved" size="small" sx={{ px: 1, bgcolor: "#4caf50" }} />;
    case "APPROVABLE":
      return <Chip label="Approvable" size="small" sx={{ px: 1, bgcolor: "#ffeb3b" }} />;
    case "UNAPPROVABLE":
      return <Chip label="Unapprovable" size="small" sx={{ px: 1, bgcolor: "#fa3030" }} />;
    case "INVALID":
      return <Chip label="Invalid" size="small" sx={{ px: 1, bgcolor: "#9e9e9e" }} />;
    default:
      return <Chip label="Unknown" size="small" sx={{ px: 1, bgcolor: "#9e9e9e" }} />;
  }
};

const CheckResultCell: FC<{ value: string; errType: boolean }> = ({ value, errType }) => {
  switch (value) {
    case "OK":
      return <>OK</>;
    case "WARNING":
      return <Chip label="WARNING" size="small" sx={{ px: 1, bgcolor: "#ffeb3b" }} />;
    case "NG":
      return <Chip label="NG" size="small" sx={{ px: 1, bgcolor: "#fa3030" }} />;
    default:
      return <Chip label="N/A" size="small" sx={{ px: 1, bgcolor: "#9e9e9e" }} />;
  }
};
// value ? (
//   <>OK</>
// ) : (
//   <Chip label="NG" size="small" sx={{ px: 1, bgcolor: errType ? "#fa3030" : "#fa9a29" }} />
// );

const getColumnDef = (workflowType: string): MRT_ColumnDef<CheckResultNode>[] => {
  let result: MRT_ColumnDef<CheckResultNode>[] = [
    {
      accessorKey: "pe_code",
      header: "CE Code",
      size: 150,
    },
    {
      accessorKey: "next_action",
      header: "Next Action",
      size: 250,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 150,
      Cell: ({ cell, row }) => <CheckResultStatusCell value={row.original.status} />,
    },
  ];
  if (workflowType.startsWith("SUBMITTING_FILE_")) {
    result = [
      ...result,
      {
        accessorKey: "name_chk",
        header: "Name Check",
        size: 150,
        Cell: ({ cell, row }) => <CheckResultCell value={row.original.name_chk!} errType />,
      },
      {
        header: "Info",
        size: 150,
        Cell: ({ cell, row }) => convToInfoElement(row.original),
      },
    ];
  } else {
    switch (workflowType) {
      case "CSV_DATA_UPLOADER":
      case "TANGO":
      case "TOP_SIDE":
        result = [
          ...result,
          {
            accessorKey: "type_chk",
            header: "Type Check",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.type_chk} errType />,
          },
          {
            accessorKey: "timing_chk",
            header: "Timing Check",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.timing_chk} errType />,
          },
          {
            header: "Info",
            size: 150,
            Cell: ({ cell, row }) => convToInfoElement(row.original),
          },
        ];
        break;
      case "STANDARD_TEMPLATE":
        result = [
          ...result,
          {
            accessorKey: "type_chk",
            header: "Type",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.type_chk} errType />,
          },
          {
            accessorKey: "mandatory_field_chk",
            header: "Mandatory",
            size: 150,
            Cell: ({ cell, row }) => (
              <CheckResultCell value={row.original.mandatory_field_chk} errType={false} />
            ),
          },
          {
            accessorKey: "changed_chk",
            header: "Immutable",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.changed_chk} errType />,
          },
          {
            accessorKey: "compared_chk",
            header: "Reconciliation",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.compared_chk} errType />,
          },
          {
            header: "Info",
            size: 200,
            Cell: ({ cell, row }) => convToInfoElement(row.original),
          },
        ];
        break;
      default:
        result = [
          ...result,
          {
            accessorKey: "name_chk",
            header: "Name Check",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.name_chk!} errType />,
          },
          {
            header: "Info",
            size: 150,
            Cell: ({ cell, row }) => convToInfoElement(row.original),
          },
        ];
    }
  }

  return result;
};
type JobResultListProps = {
  minHeight: number;
  maxHeight: number;
  data: CheckResultNode[];
  workflowType: string;
  onAction: (act: string, data: unknown) => void;
  onSelectChange: (selectedItems: CheckResultNode[]) => void;
};

const CheckResultList: FC<JobResultListProps> = ({
  workflowType,
  data,
  onAction,
  onSelectChange,
  minHeight,
  maxHeight,
}) => {
  const columns = useMemo<MRT_ColumnDef<CheckResultNode>[]>(
    () => getColumnDef(workflowType),
    [workflowType]
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const handleRowSelChanged = useCallback(
    (rowSel: Updater<RowSelectionState>) => {
      const newSelection = rowSel instanceof Function ? rowSel(rowSelection) : rowSel;
      setRowSelection(newSelection);
      onSelectChange(data.filter((d) => Object.keys(newSelection).includes(d.id)));
    },
    [onSelectChange, rowSelection, data]
  );

  return (
    <Paper elevation={0} sx={{}}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableRowSelection={(row) => true}
        getRowId={(jd) => jd.id}
        onRowSelectionChange={handleRowSelChanged}
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableRowVirtualization
        state={{ rowSelection }}
        muiTableContainerProps={{
          sx: { minHeight: minHeight ?? 250, maxHeight: maxHeight ?? 500 },
        }}
        muiTableBodyRowProps={({ row }) => ({
          hover: true,
          sx: {
            backgroundColor: row.original.already_approved === "NG" ? "#A0A0A0" : "inherit",
          },
        })}
        // muiTableBodyRowProps=
      />
    </Paper>
  );
};

const getDetailColumnDef = (workflowType: string): MRT_ColumnDef<CheckResultDetailNode>[] => {
  let result: MRT_ColumnDef<CheckResultDetailNode>[] = [
    {
      accessorKey: "pe_code",
      header: "CE Code",
      size: 150,
    },
    {
      accessorKey: "dp_number",
      header: workflowType.startsWith("SUBMIT") ? "File Type" : "Item ID",
      size: 120,
    },
    {
      accessorKey: "dp_value",
      header: "Value",
      size: 120,
      Cell: ({ cell, row }) =>
        row.original.dp_value === undefined ? "<BLANK>" : (row.original.dp_value as string),
    },
  ];
  if (workflowType.startsWith("SUBMITTING_FILE_")) {
    result = [
      ...result,
      {
        accessorKey: "name_chk",
        header: "Name Check",
        size: 150,
        Cell: ({ cell, row }) => <CheckResultCell value={row.original.name_chk!} errType />,
      },
      {
        header: "Info",
        size: 150,
        Cell: ({ cell, row }) => convToInfoElement(row.original),
      },
    ];
  } else {
    switch (workflowType) {
      case "CSV_DATA_UPLOADER":
      case "TANGO":
      case "TOP_SIDE":
        result = [
          ...result,
          {
            accessorKey: "type_chk",
            header: "Type Check",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.type_chk} errType />,
          },
          {
            accessorKey: "timing_chk",
            header: "Timing Check",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.timing_chk} errType />,
          },
          {
            header: "Info",
            size: 150,
            Cell: ({ cell, row }) => convToInfoElement(row.original),
          },
        ];
        break;
      case "STANDARD_TEMPLATE":
        result = [
          ...result,
          {
            accessorKey: "type_chk",
            header: "Type Check",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.type_chk} errType />,
          },
          {
            accessorKey: "mandatory_field_chk",
            header: "Mandatory",
            size: 150,
            Cell: ({ cell, row }) => (
              <CheckResultCell value={row.original.mandatory_field_chk} errType={false} />
            ),
          },
          {
            accessorKey: "changed_chk",
            header: "Immutable",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.changed_chk} errType />,
          },
          {
            accessorKey: "compared_chk",
            header: "Reconciliation",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.compared_chk} errType />,
          },
          {
            header: "Info",
            size: 150,
            Cell: ({ cell, row }) => convToInfoElement(row.original),
          },
        ];
        break;
      default:
        result = [
          ...result,
          {
            accessorKey: "name_chk",
            header: "Name Check",
            size: 150,
            Cell: ({ cell, row }) => <CheckResultCell value={row.original.name_chk!} errType />,
          },
          {
            header: "Info",
            size: 150,
            Cell: ({ cell, row }) => convToInfoElement(row.original),
          },
        ];
    }
  }

  return result;
};
type CheckResultDetailListProps = {
  data: CheckResultDetailNode[];
  workflowType: string;
};

const CheckResultDetailList: FC<CheckResultDetailListProps> = ({ workflowType, data }) => {
  const columns = useMemo<MRT_ColumnDef<CheckResultDetailNode>[]>(
    () => getDetailColumnDef(workflowType),
    [workflowType]
  );

  return (
    <Paper elevation={0} sx={{}}>
      <MaterialReactTable
        columns={columns}
        data={data}
        getRowId={(d) => d.id}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { height: 300 } }}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};
type CheckResultProps = {
  jobId: string;
  onAction: (act: string, data: unknown) => void;
};

const CheckResultPanel: FC<CheckResultProps> = ({ jobId, onAction }) => {
  const [selectedCEList, setSelectedCEList] = useState<string[]>([]);
  const [ftype, setFtype] = useState<SelectNode>(kOpts_FileType[0]);
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const [showDetail, setShowDetail] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const { data: jobData, isSuccess: isGetJobSuccess } = useGetJobQuery(
    { jobId },
    { skip: jobId === undefined }
  );

  const [approveJob, { isLoading: isProceedLoading, isSuccess: isProceedSuccess }] =
    useApproveJobMutation();
  const { data: getProcessResultData, isLoading: isGetProcessResultLoading } =
    useGetProcessResultQuery(
      {
        filter: { ...filters, jobId, level: showDetail ? "DP" : "PE" },
      },
      {
        skip: jobId === undefined,
      }
    );
  const withIdData: CheckResultDetailNode[] | CheckResultNode[] | undefined = useMemo(
    () =>
      getProcessResultData?.map((data, index) => ({
        ...data,
        id: index.toString(),
      })) as CheckResultDetailNode[] | CheckResultNode[] | undefined,
    [getProcessResultData]
  );

  const statusInfo = useMemo(() => {
    if (showDetail) {
      return {};
    }
    if (!getProcessResultData) {
      return {};
    }

    const checkResultData = getProcessResultData as CheckResultNode[];
    return checkResultData.reduce(
      (acc, { pe_code, status }) => {
        acc[pe_code] = status;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [getProcessResultData, showDetail]);

  const jobTargetCEList = useMemo(() => {
    if (!jobData) {
      return [];
    }
    return jobData.JobDetails!.map((ce) => ce.PECode);
  }, [jobData]);

  const [prepareAndDownloadFile] = usePrepareAndDownloadFileMutation();

  const { workflowType } = jobData ?? {};
  const isSendBackPossibleWorkflow =
    workflowType === "STANDARD_TEMPLATE" ||
    workflowType === "TOP_SIDE" ||
    workflowType?.startsWith("SUBMITTING_FILE");
  const isSendBackPossibleJob = jobData?.sendbackable;

  const { openSendBackDialog, renderSendBackDialog, isSendBackJobSuccess } = useSendBackJobDialog();

  const handleToggleDetail = (event: ChangeEvent<HTMLInputElement>) => {
    setShowDetail(event.target.checked);
  };

  const handleFileTypeChanged = (v: SelectNode) => {
    setFtype(v);
  };

  const handleAction = (act: string, data: unknown) => {
    onAction(act, data);
  };

  const handleSelectChange = (selectItems: CheckResultNode[]) => {
    setSelectedCEList(selectItems.map((item) => item.pe_code));
  };
  const handleClickFilter = () => {
    setFilterOpen(true);
  };
  const handleFilterClose = (tp: string, ary: CompanyData[]): void => {
    setFilterOpen(false);
  };

  const handleProceed = async () => {
    const peList = selectedCEList;
    try {
      await approveJob({
        jobId,
        PEList: peList,
      }).unwrap();
      enqueueSnackbar("approved", { variant: "success" });
    } catch (error) {
      if (isTimeoutError(error)) {
        enqueueSnackbar("Please wait for email", { variant: "success" });
      } else {
        enqueueSnackbar(`cannot approve job[${jobId}]`, { variant: "error" });
      }
    }
  };

  const handleSendBack = async () => {
    const peList = selectedCEList;

    const workflowType = jobData?.workflowType;
    if (!isString(workflowType)) {
      return;
    }

    openSendBackDialog({
      targetCEList: peList,
      targetJobId: jobId,
      workflowType,
    });
  };

  const handleDownloadResult = () => {
    const peList = selectedCEList;
    const fileType = "PROCESS_RESULT";

    prepareAndDownloadFile({
      prepareRequest: {
        jobId,
        fileType,
        CECodeList: peList,
      },
      generateDownloadURLRequest: {
        category: "WORKFLOW",
        fileName: "result.csv",
        fileType,
      },
    }).catch();
  };

  const isDisabledProceedButton = (): boolean => {
    if (!isGetJobSuccess) {
      return true;
    }
    if (showDetail) {
      return true;
    }
    if (jobData?.type !== "IN_PROGRESS") {
      return true;
    }
    // if (isSendBackJobSuccess) {
    //   return true;
    // }
    if (!Object.keys(selectedCEList).length) {
      return true;
    }

    for (const pe_code of selectedCEList) {
      if (statusInfo[pe_code] !== "APPROVABLE") {
        return true;
      }
    }
    for (const pe_code of selectedCEList) {
      if (!jobTargetCEList.includes(pe_code)) {
        return true;
      }
    }
    // jobData?.type !== "IN_PROGRESS" ||
    //   showDetail ||
    //   !Object.keys(selectedCEList).length ||
    //   isProceedSuccess ||
    //   !isGetJobSuccess ||
    //   isSendBackJobSuccess;
    return false;
  };

  const isDisabledSendBackButton = (): boolean => {
    if (!isGetJobSuccess) {
      return true;
    }
    if (showDetail) {
      return true;
    }
    if (!isSendBackPossibleWorkflow) {
      return true;
    }
    if (!isSendBackPossibleJob) {
      return true;
    }
    if (isProceedLoading) {
      return true;
    }
    if (!Object.keys(selectedCEList).length) {
      return true;
    }
    if (workflowType === "STANDARD_TEMPLATE") {
      for (const pe_code of selectedCEList) {
        if (jobData.CEStatusInfo[pe_code]?.sendbackable !== true) {
          return true;
        }
      }
    }

    if (workflowType === "TOP_SIDE" || workflowType?.startsWith("SUBMITTING_FILE")) {
      if (jobData.attribute !== "APPROVE" && jobData.type !== "DONE") {
        return true;
      }
      for (const pe_code of selectedCEList) {
        if (statusInfo[pe_code] === "INVALID") {
          return true;
        }
        if (!jobTargetCEList.includes(pe_code)) {
          return true;
        }
      }
    }

    // for (const pe_code of selectedCEList) {
    //   if (!jobTargetCEList.includes(pe_code)) {
    //     return true;
    //   }
    // }

    // showDetail ||
    //   !Object.keys(selectedCEList).length ||
    //   isProceedLoading ||
    //   !isGetJobSuccess ||
    //   !isSendBackPossibleWorkflow ||
    //   !isSendBackPossibleJob ||
    //   isSendBackJobSuccess;
    return false;
  };

  return (
    <Paper elevation={0} sx={{ px: 2 }}>
      <Grid container flexDirection={{ xs: "column", sm: "row" }}>
        <Grid xs={12} sm={12} sx={{ textAlign: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={showDetail}
                onChange={handleToggleDetail}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Detail View"
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{}}>
          <FileCheckFilterPanel filters={filters} onChanged={(v) => setFilters(v)} />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mb: 2 }}>
          <Box>
            <Stack
              direction="row"
              sx={{
                mt: 1,
                width: "100%",
                gap: "0.5rem",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ width: "50px" }}>Action</Typography>
              <LoadingButton
                variant="outlined"
                loading={isProceedLoading}
                disabled={isDisabledProceedButton()}
                onClick={handleProceed}
              >
                Proceed
              </LoadingButton>
              <Button
                variant="outlined"
                disabled={isDisabledSendBackButton()}
                onClick={handleSendBack}
              >
                Send back
              </Button>
              <Box sx={{ flex: 1 }} />
              <Button
                variant="outlined"
                disabled={showDetail || !Object.keys(selectedCEList).length}
                onClick={handleDownloadResult}
              >
                Check Result Download
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid xs={12} sm={12}>
          {showDetail ? (
            <CheckResultDetailList
              workflowType={jobData?.workflowType ?? ""}
              data={(withIdData as CheckResultDetailNode[]) ?? []}
            />
          ) : (
            <CheckResultList
              workflowType={jobData?.workflowType ?? ""}
              data={(withIdData as CheckResultNode[]) ?? []}
              onAction={handleAction}
              onSelectChange={handleSelectChange}
              minHeight={300}
              maxHeight={800}
            />
          )}
        </Grid>
      </Grid>
      {renderSendBackDialog()}
    </Paper>
  );
};

function convToInfoElement(data: CheckResultNode | CheckResultDetailNode): JSX.Element {
  const infoStr: string[] = [];
  if (data.already_approved === "NG") {
    infoStr.push("ALREADY_APPROVED");
  }
  if (data.ce_chk === "NG") {
    infoStr.push("INVALID_CE");
  }
  if (data.dp_chk === "NG") {
    infoStr.push("INVALID_DP");
  }
  if (data.file_type_chk === "NG") {
    infoStr.push("INVALID_FILE_TYPE");
  }
  if (data.version_chk === "NG") {
    infoStr.push("VERSION_ERROR");
  }
  if (data.submit_waiting_chk === "NG") {
    infoStr.push("SUBMIT_WAITING");
  }
  if (!data.timing_chk) {
    infoStr.push("INVALID_TIMING");
  }
  if (data.duplicated === "NG") {
    infoStr.push("DUPLICATED_FILE");
  }
  if (data.group_readiness_chk === "NG") {
    infoStr.push("GROUP_NOT_READIED");
  }
  const infoText = infoStr.join("\n");

  const ret = (
    <div>
      <span>{infoText}</span>
    </div>
  );
  return ret;
}

export { CheckResultPanel, FileCheckFilterPanel };
